import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import "@evag/ec-react/dist/main.css";

import { Form } from '@evag/ec-react';

import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Img from "gatsby-image";
import SEO from '../components/SEO';
import CountSignatures from '../components/CountSignatures';
import {
  FaFacebook,
  FaFlickr,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/all";

export default function render({ data }) {
  CampaignForm.channelId = data.site.meta.channel;
  const contact = {
    title: `Coletivo Juntos`,
    addressLine1: "Rua Parintins, 58",
    addressLine2:
      "Barra Funda, 01155-020 São Paulo - SP",
    email: "contato@juntos.org.br",
    emailUrl: "mailto:contato@juntos.org.br",
    phone: "",
    phoneUrl: "",
    facebook: "https://facebook.com/juventudeemluta/",
    twitter: "https://twitter.com/coletivojuntos/",
    instagram: "https://instagram.com/coletivojuntos/",
    youtube: "https://instagram.com/coletivojuntos/",
    flicker: "https://www.flickr.com/photos/184024294@N06",
  };

  return (
    <Layout>
      <SEO title={data.site.meta.title} />

      <nav>
        <div className="wrap">
          <a
            id="btn-home"
            href="https://juntos.org.br"
            title="Página Inicial"
          >
            <Img
              style={{ width: "30%", marginLeft: "16px" }}
              alt="Coletivo Juntos"
              fluid={data.nav.childImageSharp.fluid}
            />
          </a>
        </div>
      </nav>


      <Hero
        background={data.fundo.childImageSharp.fluid}
        logo={data.hero.childImageSharp.fluid}
        title={data.site.meta.title}
        description={data.site.meta.description}
        channel={data.site.meta.channel}
      />

      <main className="main">
        <div className="wrap, texto">
          <h1 align="center">NÓS POR NÓS!</h1>
          <h3 align="center">DEFENDER A ESCOLA PARA TRANSFORMAR O MUNDO!</h3>
          <br></br>
          <br></br>
          <h3>1. CONTRA A EVASÃO E O DESMONTE DA ESCOLA, ESTUDAR É DIREITO!</h3>
          <p>
          Bolsonaro iniciou 2022 cortando R$740 milhões do orçamento da educação. É uma péssima notícia para quem está voltando para a sala de aula de escolas defasadas, com verba insuficiente para alimentação, falta de professores e demais problemas que pioraram com a pandemia. O corte do Bolsonaro tira dinheiro da Educação Básica e trava uma verba de apoio para as instituições federais de ensino superior. 
          </p>

          <p>
          Como aconteceu em 2019, o movimento em defesa da educação deve estar na vanguarda da luta contra o bolsonarismo e todo tipo de negacionismo. Defendemos a escola com investimento em estrutura, tecnologia, merenda, pensamento crítico, esporte e valorização das educadoras. Queremos uma escola democrática, sem opressões e valorização da juventude.  Essa é a nossa luta! Defendemos a escola para mudar o mundo!
          </p>


          <h3>2. ESSA REFORMA NÃO NOS SERVE! CONTRA A ELITIZAÇÃO DO ACESSO AO ENSINO SUPERIOR.</h3>
          <p>
          A reforma do Ensino Médio aprovada no governo Temer foi feita sem nenhum diálogo com os professores e estudantes e gerou em 2016 milhares de ocupações de escolas. Sua implementação está prevista para esse ano, e retira matérias que incentivam o pensamento crítico das nossas escolas, ampliando a precarização do ensino e um modelo de educação tecnicista. Queremos a revogação da Reforma do Ensino Médio e a construção de um novo modelo feito a partir da educação popular.
          </p>

          <p>
          Essa Reforma também irá aumentar o abismo do acesso de estudantes de escolas públicas ao Ensino Superior, aprofundando o que têm sido os últimos anos de ENEM, com alta taxa de evasão, com menos estudantes negros inscritos desde o início da prova. Reafirmamos que acesso à educação não pode ser privilégio, lutamos por um ENEM mais justo, contra a censura promovida pelo governo Bolsonaro.
          </p>
          
          
          <h3>3. EDUCAÇÃO POPULAR PARA A LIBERDADE - CONTRA A MILITARIZAÇÃO E O NEGACIONISMO, POR VACINA PARA TODES</h3>
          <p>A militarização das escolas é uma ação política governamental que está vigente em diversas escolas ao redor de todo o país. Uma forma em que a extrema-direita concebeu para terem o controle educacional, e assim perpetuarem suas ideias e ideais muitas vezes com fundamentos retrógados e até mesmo baseados em fake news nas escolas.  Ignorando os saberes de professores e gestores, a opinião das e dos estudantes, da comunidade escolar e que ignora o método científico educacional de emancipação popular, demandando uma política contrária à educação emancipatória de Paulo Freire.</p>
          <p>Atualmente a luta pela educação passa pela luta contra o negacionismo crescente liderado por Bolsonaro. Estamos enfrentando uma pandemia sem precedentes que já deixou mais de 600 mil mortos e sabemos que o grande culpado é Bolsonaro. Ele, que quis cobrar propina em troca da vacina, desde o início minimiza a gravidade da covid; ignora as medidas sanitárias e realiza campanha agitativa contra a vacina e contra a ciência, gerando medo e desconfiança na população. A nossa luta é pela vacinação para todes, contra o negacionismo e em defesa da ciência; em defesa da vida!</p>
          <p>A luta das e dos estudantes secundaristas deve ser por uma educação popular de qualidade baseada na ciência e que as escolas sejam espaços livres e democráticos. Não há espaço para políticas reacionárias, para o negacionismo e as fake news. A nossa luta se faz no presente por uma transformação radical na sociedade e pela emancipação das e dos estudantes e de toda a sociedade.</p>

          <h3>4. OUTRO MUNDO É URGENTE! ECOSSOCIALISMO PARA GARANTIR NOSSO FUTURO. COMBATE AO RACISMO, MACHISMO E LGBTIFOBIA</h3>
          <p>Em meio às constantes e diversas crises que estamos vivenciando não é mais possível esperar para agir. Vivemos num sistema que se sustenta historicamente por um círculo vicioso de crises que atualmente se expressa numa crise multidimensional: crise social, política, econômica, sanitária, ambiental. Basta!</p>
          <p>Em relação ao ecossistema, isso ocorre, entre outros fatores, porque a expansão capitalista, que visa o lucro, põe em risco as condições materiais de existência da humanidade e do meio ambiente. É necessário romper essa lógica e propor uma alternativa. Essa alternativa é o ecossocialismo! Uma alternativa sistêmica de ação e pensamento ecológicos fundados no marxismo ao mesmo tempo em que busca se diferenciar das experiências produtivistas do socialismo.</p>
          <p>Ao mesmo tempo em que lutamos por um novo mundo sustentado pelo ecossocialismo, temos a tarefa urgente de dar fim ao racismo, ao machismo e a LGBTIfobia em nossa sociedade. Precisamos conectar a luta do movimento secundarista pela educação à luta geral por mais direitos e pela emancipação para negras/os, LGBTIs e mulheres, dentro e fora das escolas. Somos parte da geração que não permite a opressão e o bullying e que luta pela garantia da pluralidade e da diversidade. </p>
          <p>A nossa luta, portanto, não se faz apenas vislumbrando um novo futuro, mas por um presente de lutas e enfrentamentos coletivos com mobilizações permanentes. E é essa juventude que, organizando sua indignação, acredita nessa perspectiva de luta anticapitalista, antirracista, antiLGBTIfóbica, feminista e ecossocialista. Vamos juntes!</p>

          <h3>5. NÓS POR NÓS: MOBILIZAÇÃO ESTUDANTIL PERMANENTE!</h3>
          <p>Muitos de nós nos encontramos nas lutas recentemente, em defesa da democracia e dos nossos direitos. Acreditamos que a mobilização estudantil é fundamental para mudar o país. Somos oposição à atual gestão da UBES porque apostamos que a entidade deve impulsionar à auto-organização nas escolas. Organiza o grêmio estudantil na tua escola, participa do Juntos! e vamos à luta!</p>


        </div>
      </main>

      <div id="sign" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title">Preencha o formulário abaixo e assine nosso manifesto!</h2><br></br>
          <h4 className="signatures-title">Vamos Juntes nos organizar e transformar a educação e o país!</h4>
          <br></br>
          <CountSignatures containerClass={"container-unlimited"} invertedColors={false} channel={data.site.meta.channel} />
          <br></br>
          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />
        </div>
      </div>


      <footer className="footer">
        <div className="wrap">
          <div className="content">
            <div className="contact">
              <div className="social-icons">
                <ul>
                  <li>
                    <a
                      id="btn-fb"
                      href={contact.facebook}
                      target="_top"
                      title="Contato no Facebook"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-twitter"
                      href={contact.twitter}
                      target="_top"
                      title="Contato no Twitter"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-instagram"
                      href={contact.instagram}
                      target="_top"
                      title="Contato no Instagram"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-youtube"
                      href={contact.youtube}
                      target="_top"
                      title="Contato no YouTube"
                    >
                      <FaYoutube />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-flickr"
                      href={contact.flicker}
                      target="_top"
                      title="Contato no Flicker"
                    >
                      <FaFlickr />
                    </a>
                  </li>
                </ul>
              </div>
              <p>
                {contact.title}
                <br />
                {contact.addressLine1}
                <br />
                {contact.addressLine2}
              </p>
              <p>
                Copyleft © 2011-2022 Coletivo Juntos
                <br />
                O conteúdo deste site, exceto quando proveniente de outras
                fontes ou onde especificado o contrário, está licenciado sob a
                Creative Commons by-sa 3.0 BR.
                <br />
              </p>
              <p>
                <a
                  id="btn-privacidade"
                  href="https://juntos.org.br/politica-de-privacidade"
                >
                  Política de Privacidade
                </a>
              </p>
            </div>
            <div className="logos">
              <Img
                className="logo"
                alt="Coletivo Juntos"
                fluid={data.juntos.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </footer>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    nav: file(relativePath: { eq: "logojuntos1.png" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fundo: file(relativePath: { eq: "fundoconubes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    juntos: file(relativePath: { eq: "logojuntos.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

  
  }
`;
